.voice-chat {
  margin: 20px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    h2 {
      margin-bottom: 0;
    }
  }

  .task-lists {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 80px;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    .task-list {
      flex: 1;

      h3 {
        margin-bottom: 10px;
      }
    }
  }

  .fab-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .loading-overlay {
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.7);
  }
}