.root {
  margin-top: 100px;
  margin-bottom: 40px;
  margin-left: 5%;
  margin-right: 5%;
  background: white;

  .subSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;

    .switchWrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-self: center;

      .switch {
        // margin-left: 20px;
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 100%;
      margin-bottom: 28px;
    
      > * { // Every direct child of the row will grow equally
        flex: 1; 
        margin: 0 10px; // This ensures there is space between the items
      }
    }
  }

  .toolBar {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    background-color: #c0c0c0;
    z-index:100;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 28px;

    .button {
      margin-left: 20px;
    }
  }
}