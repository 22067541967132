// .slick-dots {
//   bottom: -30px;
//   li {
//     margin: 0 3px;
//     button {
//       width: 16px;
//       height: 16px;
//       border-radius: 50%;
//       position: relative;

//       &::before {
//         content: "";
//         font-size: 0;
//         opacity: 1;
//         border: 2px solid $px-theme;
//         width: 100%;
//         height: 100%;
//         border-radius: 50%;
//         transition: 0.3s ease-in-out;
//       }
//       &::after {
//         content: "";
//         position: absolute;
//         width: 100%;
//         height: 100%;
//         left: 0;
//         top: 0;
//         transform: scale(0);
//         background: $px-theme;
//         transition: 0.3s ease-in-out;
//         border-radius: inherit;
//         z-index: 5;
//       }
//     }
//     &.slick-active {
//       button {
//         &::after {
//           transform: scale(0.6);
//         }
//       }
//     }
//   }
// }
.testimonial-wrapper,
.preview-inner {
  .slick-slide > div {
    margin: 0 10px;
  }
  .slick-list {
    margin: 0 -10px;
  }
}

// .slick-arrow {
//   display: none !important;
// }
