.signInPageRoot {
  display: flex;
  flex-direction: column;
  align-items: center;

  .signInWrapper {
    display: flex;
    flex-direction: column;
    width: 400px;
    max-width: 80%;
    margin-top: 10%;
    margin-bottom: 2%;

    .titleWrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 5%;

      .title {
        font-size: xx-large;
      }
    }

    .inputField {
      margin-bottom: 10%;
    }
  }
}