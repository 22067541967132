.create-list {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .MuiToolbar-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .MuiTextField-root {
    margin-top: 20px;
    padding: 0 20px;
  }
}